export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const searchIcon = require("../assets/search.svg");
export const defaultAsset  = require("../assets/defaultAsset.png");
export const downArrow = require("../assets/downArrow.svg");
export const copyIcon = require('../assets/copy.svg');
export const filterIcon = require('../assets/filter_ic.svg');
export const closeIconBold = require('../assets/close_ic_bold.svg');
export const downArrowSmall = require("../assets/down_arrow.svg");
export const downArrowActive = require("../assets/down_arrow_active.svg");
export const checkIcon = require("../assets/check.svg");
export const unCheckIcon = require("../assets/un-check.svg");
export const infoIcon = require("../assets/info_icon.svg");