import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getDashboardName, getMemberErrorMessages, getStartAndEndDates, getToken, getYearMonthDate, removeAllToken, setToken } from "../../../components/src/commonUsage";
import { trackEvent } from "../../../components/src/analytics";
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  emptyState: boolean;
  calendarMenu: any;
  calanderValue: any;
  dateRangeValue: any;
  openCalendar: any;
  addMemberModal: boolean;
  firstTimeUserModal: boolean;
  sliderIndex: number;
  leads_graph:any;
  maxVideo:boolean;
  dateValue:any;
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      type: "",
      dashboardData: {
        "partner": {
          name: "",
          partner_image: {
            url: ""
          }
        },
        "total_deals": [
          {
            "name": "total leads",
            "count": 0
          }
        ],
        "leads_graph": [
          {
            "name": "Closed won",
            "count": 0
          },
          {
            "name": "Closed lost",
            "count": 0
          },
          {
            "name": "Open",
            "count": 0
          },
          {
            "name": "Disqualified",
            "count": 0
          },
          {
            "name": "In progress",
            "count": 0
          }
        ],
        "conversion_value_graph": [],
        "total_leads_graph": [],
        "conversion_value": 0,
        "value_in_paipeline": 0,
        "commission_earned": 0,
        "total_closed_won_count": 0,
        "total_lead_closed_won_graph": [],
        "min_commit_value": 0,
        "over_all_closed_conversion_graph": [],
        "over_all_closed_conversion_count": 0,
        "previous_conversion_value": 0,
        "previous_total_closed_won_count": 0,
        "previous_total_deals": 0,
      },
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      emptyState: true,
      calendarMenu: null,
      calanderValue: 'Last 90 days',
      dateRangeValue: getStartAndEndDates('Last 90 days'),
      openCalendar: false,
      addMemberModal: false,
      firstTimeUserModal: false, // enable for onboard flow
      sliderIndex: 1,
      leads_graph: {
        "Open":{name:"Leads",count:0},
        "Terms & Pricing":{name:"Terms and pricing",count:0},
        "SQL":{name:"Leads",count:0},
        "Verbal Agreement":{name:"Verbal agreement",count:0},
        "Discovery & Specification":{name:"Discovery and specification",count:0},
        "Closed won":{name:"Closed won",count:0},
        "Solution":{name:"Solution",count:0},
        "Closed lost":{name:"Closed lost",count:0},
        "Proposal": {name:"Proposal",count:0},
        "Disqualified":{name:"Disqualified",count:0},
        "Scope Discussion":{name:"Scope discussion",count:0},
    },
    maxVideo:false,
    dateValue:[]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.getFilteredData('normal')
    if (Number(getToken('login_count') || 0) === 0) {
      this.setState({
        // addMemberModal: true,
        firstTimeUserModal: true,
        sliderIndex:Number(getToken('currentSlide') || 1)
      })
      addEventListener("beforeunload", (event) => {
        setToken('currentSlide', this.state.sliderIndex ||1)
        return "leaved the page"
      });
    }
    trackEvent("prm_home_page_visited","user lands on the the PRM portal",{dewey_code:"B3.1",flow:1})
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (getMemberErrorMessages(webResponseJson)) {
        removeAllToken();
        this.props.navigation.navigate('EmailAccountLoginBlock');
      } else if (!webResponseJson.errors) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.pieDataMapper(webResponseJson)
          this.setState({
            dashboardData: webResponseJson,
            loading: false,
            openCalendar: false,
            emptyState: webResponseJson.empty_state,
          });
          setToken('profile_name', getDashboardName(webResponseJson))
          setToken('notify_me', this.state.dashboardData?.partner.notify_me)
        }
      } else {
        this.setState({
          emptyState: true,
          loading: false
        });
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleSliderIndex = (index: any) => {
    let newIndex;
    if (index === 'next') {
      newIndex = this.state.sliderIndex < 4 ? this.state.sliderIndex + 1 : 1;
    } else if (index === 'prev') {
      newIndex = this.state.sliderIndex > 1 ? this.state.sliderIndex - 1 : 4;
      this.setState({maxVideo:false})
    } else {
      newIndex = index;
    }
    this.setState({ sliderIndex: newIndex });
  };
  handleCalendarClick = (event: any) => {
    if (event) {
      event.persist();
      this.setState({ calendarMenu: event.currentTarget,openCalendar:false });
    }
  };
  handleCalendarClose = () => {
    this.setState({ calendarMenu: null });
  }
  handleCalendarSelect = (text: any) => {
    if (text === "Custom...") {
      if (this.state.calanderValue === 'Custom...') {
        this.setState({
          openCalendar: true,
          calanderValue: text,
          calendarMenu: null,
        })
      } else {
        this.setState({
          openCalendar: true,
          calanderValue: text,
          calendarMenu: null,
        })
      }

    } else if (text === "All Time") {
      this.setState({ calanderValue: text, calendarMenu: null, openCalendar: false, dateValue:[], dateRangeValue:[] }, () => {
        this.getFilteredData('allTime')
      });
    } else {
      this.setState({ calanderValue: text, calendarMenu: null, dateRangeValue: getStartAndEndDates(text), openCalendar: false, dateValue:[] }, () => {
        this.getFilteredData('normal')
      });
    }
  };
  handleDateChange = (item: any) => {
    this.setState({ dateValue: item });
  };
  getFilteredData = (value: any) => {
    this.setState({ loading: true })
    const webHeader = {
      token: getToken('authToken')
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    if (value === 'normal') {
      webRequestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${webConfigJSON.dashboardGetUrl}?start_date=${getYearMonthDate(this.state.dateRangeValue[0])}&end_date=${getYearMonthDate(this.state.dateRangeValue[1])}`
      );
    }
    if (value === 'allTime') {
      webRequestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        webConfigJSON.dashboardGetUrl
      );
    }

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  }
  closeCalander = () => {
    this.setState({
      openCalendar: false
    })
  }
  navigateToLeadManagement = () => {
    this.props.navigation.navigate(
      "RegisterReferral"
    )
  }
  navigateToLeadDashboard = () => {
    this.props.navigation.navigate('LeadDashboard')
  }
  navigateToCommissionDashboard = () => {
    this.props.navigation.navigate('Commissionreports2')
  }
  openWelcomeModal() {
    this.setState({
      addMemberModal: true
    })
  }
  closeModal() {
    setToken('login_count', 1)
    localStorage.removeItem('currentSlide')
    this.setState({
      addMemberModal: false,
      firstTimeUserModal: false
    })
  }
  pieDataMapper = (data: any) => {
    const mappedKeysForPie = this.state.leads_graph
    data?.leads_graph?.map((item: any) => {
      if (mappedKeysForPie[item.name]) {
        mappedKeysForPie[item.name] = { ...item }
      }
    })

    this.setState({
      leads_graph: mappedKeysForPie
    })
  }

  navigateToNewDeal = () => {
    this.props.navigation.navigate('RegisterReferral')
  }
  // Customizable Area End
}
