import React from "react";
// Customizable Area Start
import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  TextField,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Divider,
  Select,
  MenuItem,
  Menu,
  Modal,
  Tooltip,
  Paper,
  Popover,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
  CircularProgress
} from "@material-ui/core";

import SideNav from "../../../components/src/SideNav";
import TopNavBar from "../../../components/src/TopNavBar";
import { checkIcon, closeIconBold, copyIcon, defaultAsset, downArrow, downArrowSmall, filterIcon, searchIcon, unCheckIcon, infoIcon } from "./assets";
import { closeIcon, emptyAssets } from "../../leadmanagement/src/assets";
import './css/styles.css'
import { Document, Page } from 'react-pdf';
import Loader from "../../../components/src/Loader.web";
import Carousel from 'react-material-ui-carousel'
import AssetDashboardSkeleton from "./AssetDashboardSkeleton";
import { capitalizeFirstLetter, copyToClipboard, downloadFile, getCategoryName, getCategoryValue, getCheckMarkIcon, getIsChecked, getMonthDateFormat, getPageCount } from "../../../components/src/commonUsage";
import { checkMark } from "../../companycontactpage2/src/assets";
import { Pagination } from "@material-ui/lab";
import { trackEvent } from "../../../components/src/analytics";
// Customizable Area End

import AssetsDashboardController, {
  Props,
  configJSON,
} from "./Assetsdashboardcontroller";


export default class AssetsDashboardWeb extends AssetsDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start    
    // Customizable Area End
  }

  // Customizable Area Start   
  async componentDidMount() {
    trackEvent("prm_home_page_visited","user lands on the the PRM portal",{dewey_code:"B3.1",flow:1})
    await this.getFilters()
    await this.getAssets('normal')
    trackEvent('assets_and_library_accessed', "User clicks on Assets and Library section within the left navigation pane", {dewey_code:"B3.5",flow:5});
  }

  emptyTableInfo = () => {
    return <> {
      <Box className="empty-data-block height-lg">
        <Box className="empty-content">
          <Box className="empty-img">
            <img src={emptyAssets} />
          </Box>
          <Box className="content">
            <Typography className="title-sm">No assets found</Typography>
            <Typography className="empty-sub-text">Looks like there aren't any assets added yet</Typography>
          </Box>
        </Box>
      </Box>
    }
    </>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start        
    const { sortMenu, filterPopover, suggestionPopover, circularLoadingStates } = this.state;
    const sortOpen = Boolean(sortMenu);
    const filterOpen = Boolean(filterPopover);
    const suggestionOpen = Boolean(suggestionPopover);
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box style={webStyles.container}>
        <style>
          {`
            .MuiButton-startIcon {
              position: relative;
              top: 5px;
            }
            .MuiCircularProgress-root {
              width: 15px !important;
              height: 15px !important;
            }
            .MuiCircularProgress-root svg {
              width: 15px !important;
              height: 15px !important;
            }
            .MuiCircularProgress-root::before {
              width: 15px !important;
              height: 15px !important;
              border: none !important;
            }
          `}
        </style>
        <Grid container>
          <Grid item xs={3} md={2} className="sidebar-wrapper">
            <SideNav {...this.props.navigation} />
          </Grid>
          <Grid item xs={9} md={10} className="main-content-wrapper">
            <Grid>
              <TopNavBar currentPage={{ name: 'Asset library', path: 'asset-library' }} />
              <Box style={webStyles.mainContainer}>
                <Box className="dashboard-nav-container">
                  <Box className="dashboard-heading">
                    <Box>
                      <Typography className="asset-heading-main">
                        Asset library
                      </Typography>
                      <Typography className="asset-heading-sub">
                        Find assets to help leads to know more about Builder.ai.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="filter-block-wrapper top-spacing-lg">
                  <Box className="table-search-text filter-inner-wrap">
                    <Box className="filter-left-block">
                      {
                        this.state.searchQuery.length > 0 ?
                          this.state.assetsData.user_library?.data?.attributes?.length === 0 ?
                            <Typography className="search-results-text">0 Assets</Typography>
                            :
                            <Typography className="searchbar-text">{`Search results for "${this.state.searchQuery}"`}</Typography>
                          :
                          <Typography className="assets-count-text">Assets {this.state.assetsData.user_library?.meta.total_count > 0 ? `(${this.state.assetsData.user_library?.meta.total_count})` : ""}</Typography>
                      }
                    </Box>
                    <Box className="filter-right-block">
                      <Box className="right-inner-wrap">
                        {this.state.searchQuery.length > 0 ?
                          this.state.assetsData.user_library?.data?.attributes?.length === 0 ?
                            <></>
                            :
                            <>
                              <Box className="sortby-wrapper">
                                <div>
                                  <Button
                                    className="sort-deal-button"
                                    id="sort-button"
                                    data-test-id="sort-button"
                                    aria-controls={sortOpen ? 'sort-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={sortOpen ? 'true' : undefined}
                                    onClick={(event) => this.handleSortClick(event)}
                                    disableRipple
                                  >
                                    <Typography className="asset-sort-text">
                                      Sort by
                                    </Typography>
                                    <Typography
                                      className="text-transform sort-value-text">{this.state.sortValue}
                                      <label className="dropdown-arrow"> <img src={downArrow} /></label>
                                    </Typography>
                                  </Button>
                                  <Menu
                                    style={{
                                      top: '52px',
                                      borderRadius: '8px'
                                    }}
                                    id="sort-menu"
                                    aria-labelledby="sort-button"
                                    anchorEl={sortMenu}
                                    open={sortOpen}
                                    onClose={this.handleMenuClose}
                                  >
                                    <MenuItem id="sort-1" className="menu-item-text" onClick={() => this.handleSortClose("Latest")}>Latest {getCheckMarkIcon('latest', checkMark, this.state.sortValue)}</MenuItem>
                                    <MenuItem id="sort-2" className="menu-item-text" onClick={() => this.handleSortClose('Oldest')}>Oldest {getCheckMarkIcon('oldest', checkMark, this.state.sortValue)}</MenuItem>
                                    <MenuItem id="sort-3" className="menu-item-text" onClick={() => this.handleSortClose('A-Z')}>A-Z {getCheckMarkIcon('a-z', checkMark, this.state.sortValue)}</MenuItem>
                                    <MenuItem id="sort-4" className="menu-item-text" onClick={() => this.handleSortClose('Z-A')}>Z-A {getCheckMarkIcon('z-a', checkMark, this.state.sortValue)}</MenuItem>
                                  </Menu>
                                </div>
                              </Box>
                              <Box className="filter-popup-wrapper">
                                <Button aria-describedby="filter-popup" className={filterOpen ? "filter-popup-button open-popover" : "filter-popup-button"} onClick={(event) => this.handleFilterClick(event)}>
                                  <img src={filterIcon} alt="filter" className="filter-ic" />
                                  Filters {this.state.checkedFilterItems.length > 0 && <span className="filter-indicator"></span>}
                                </Button>
                                <Popover
                                  id="filter-popup"
                                  open={filterOpen}
                                  anchorEl={filterPopover}
                                  style={{
                                    top: '8px',
                                  }}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                  }}
                                  onClose={this.handleFilterClose}
                                  PaperProps={{
                                    className: 'filter-menu-popover'
                                  }}
                                >
                                  <Box className="filter-option-wrapper">
                                    <Box className="filter-header">
                                      <Box className="left-block">
                                        <Typography className="title">Filters <span>{this.state.checkedFilterItems.length > 0 && `(${this.state.checkedFilterItems.length})`}</span></Typography>
                                      </Box>
                                      <Box className="right-block">
                                        <label onClick={() => {
                                          this.setState({checkedFilterItems:[]})
                                          this.clearFilters()
                                          }} className="clear-link">Clear all</label>
                                        <IconButton className="close-btn" onClick={this.handleFilterClose}><img src={closeIcon} alt="close" /></IconButton>
                                      </Box>
                                    </Box>
                                    <Box className="filter-category-wrap">
                                      {this.state.filterArray?.map((item: any, index: any) => {
                                        const filterKey = item.category_value;

                                        return (
                                          <Accordion
                                            key={index}
                                            expanded={this.state.expanded === `panel${index + 1}`}
                                            onChange={this.handleFilterCategoryChange(`panel${index + 1}`)}
                                          >
                                            <AccordionSummary
                                              expandIcon={<img className="arrow-img" src={downArrowSmall} alt="Expand Icon" />}
                                              aria-controls={`panel${index + 1}bh-content`}
                                              id={`panel${index + 1}bh-header`}
                                            >
                                              <Typography >{item.category_name}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                              <ul className="checkbox-group">
                                                {item.values?.map((value: any, index2: any) => {
                                                  return (
                                                    <li key={index2}>
                                                      <FormControlLabel
                                                        style={{ textTransform: 'capitalize' }}
                                                        control={
                                                          <Checkbox
                                                            data-test-id="filter"
                                                            checked={getIsChecked(value.value_name, this.state.checkedFilterItems) || false}
                                                            icon={<img src={unCheckIcon} alt="Unchecked Icon" />}
                                                            checkedIcon={<img src={checkIcon} alt="Checked Icon" />}
                                                            name={value.value_name}
                                                            onChange={(e) => this.handleChangeFilter(value.value_name, e.target.checked, filterKey)}
                                                          />
                                                        }
                                                        label={value.value_name}
                                                      />
                                                    </li>
                                                  );
                                                })}
                                              </ul>
                                            </AccordionDetails>
                                          </Accordion>
                                        );
                                      })}
                                    </Box>
                                  </Box>
                                </Popover>
                              </Box>
                            </>
                          :
                          <>
                            <Box className="sortby-wrapper">
                              <div>
                                <Button
                                  className="sort-deal-button"
                                  id="sort-button"
                                  data-test-id="sort-button"
                                  aria-controls={sortOpen ? 'sort-menu' : undefined}
                                  aria-haspopup="true"
                                  aria-expanded={sortOpen ? 'true' : undefined}
                                  onClick={(event) => this.handleSortClick(event)}
                                  disableRipple
                                >
                                  <Typography className="asset-sort-text">
                                    Sort by
                                  </Typography>
                                  <Typography
                                    className="text-transform sort-value-text">{this.state.sortValue}
                                    <label className="dropdown-arrow"> <img src={downArrow} /></label>
                                  </Typography>
                                </Button>
                                <Menu
                                  style={{
                                    top: '52px',
                                    borderRadius: '8px'
                                  }}
                                  id="sort-menu"
                                  aria-labelledby="sort-button"
                                  anchorEl={sortMenu}
                                  open={sortOpen}
                                  onClose={this.handleMenuClose}
                                >
                                  <MenuItem id="sort-1" className="menu-item-text" onClick={() => this.handleSortClose("Latest")}>Latest {getCheckMarkIcon('latest', checkMark, this.state.sortValue)}</MenuItem>
                                  <MenuItem id="sort-2" className="menu-item-text" onClick={() => this.handleSortClose('Oldest')}>Oldest {getCheckMarkIcon('oldest', checkMark, this.state.sortValue)}</MenuItem>
                                  <MenuItem id="sort-3" className="menu-item-text" onClick={() => this.handleSortClose('A-Z')}>A-Z {getCheckMarkIcon('a-z', checkMark, this.state.sortValue)}</MenuItem>
                                  <MenuItem id="sort-4" className="menu-item-text" onClick={() => this.handleSortClose('Z-A')}>Z-A {getCheckMarkIcon('z-a', checkMark, this.state.sortValue)}</MenuItem>
                                </Menu>
                              </div>
                            </Box>
                            <Box className="filter-popup-wrapper">
                              <Button aria-describedby="filter-popup" className={filterOpen ? "filter-popup-button open-popover" : "filter-popup-button"} onClick={(event) => this.handleFilterClick(event)}>
                                <img src={filterIcon} alt="filter" className="filter-ic" />
                                Filters {this.state.checkedFilterItems.length > 0 && <span className="filter-indicator"></span>}
                              </Button>
                              <Popover
                                id="filter-popup"
                                open={filterOpen}
                                anchorEl={filterPopover}
                                style={{
                                  top: '8px',
                                }}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                                onClose={this.handleFilterClose}
                                PaperProps={{
                                  className: 'filter-menu-popover'
                                }}
                              >
                                <Box className="filter-option-wrapper">
                                  <Box className="filter-header">
                                    <Box className="left-block">
                                      <Typography className="title">Filters <span>{this.state.checkedFilterItems.length > 0 && `(${this.state.checkedFilterItems.length})`}</span></Typography>
                                    </Box>
                                    <Box className="right-block">
                                      <label onClick={() => {
                                        this.setState({checkedFilterItems:[]})
                                        this.clearFilters()
                                        }} className="clear-link">Clear all</label>
                                      <IconButton className="close-btn" onClick={this.handleFilterClose}><img src={closeIcon} alt="close" /></IconButton>
                                    </Box>
                                  </Box>
                                  <Box className="filter-category-wrap">
                                    {this.state.filterArray?.map((item: any, index: any) => {
                                      const filterKey = item.category_value;

                                      return (
                                        <Accordion
                                          key={index}
                                          expanded={this.state.expanded === `panel${index + 1}`}
                                          onChange={this.handleFilterCategoryChange(`panel${index + 1}`)}
                                        >
                                          <AccordionSummary
                                            expandIcon={<img className="arrow-img" src={downArrowSmall} alt="Expand Icon" />}
                                            aria-controls={`panel${index + 1}bh-content`}
                                            id={`panel${index + 1}bh-header`}
                                          >
                                            <Typography>{item.category_name}</Typography>
                                          </AccordionSummary>
                                          <AccordionDetails>
                                            <ul className="checkbox-group">
                                              {item.values?.map((value: any, index2: any) => {
                                                return (
                                                  <li key={index2}>
                                                    <FormControlLabel
                                                      style={{ textTransform: 'capitalize' }}
                                                      control={
                                                        <Checkbox
                                                          data-test-id="filter"
                                                          checked={getIsChecked(value.value_name, this.state.checkedFilterItems) || false}
                                                          icon={<img src={unCheckIcon} alt="Unchecked Icon" />}
                                                          checkedIcon={<img src={checkIcon} alt="Checked Icon" />}
                                                          name={value.value_name}
                                                          onChange={(e) => this.handleChangeFilter(value.value_name, e.target.checked, filterKey)}
                                                        />
                                                      }
                                                      label={value.value_name}
                                                    />
                                                  </li>
                                                );
                                              })}
                                            </ul>
                                          </AccordionDetails>
                                        </Accordion>
                                      );
                                    })}
                                  </Box>
                                </Box>
                              </Popover>
                            </Box>
                          </>
                        }
                        <Box >
                          <Box>
                            <TextField
                              data-test-id="search-input"
                              className="search-input"
                              fullWidth
                              size="small"
                              placeholder="Search for assets"
                              variant="outlined"
                              value={this.state.searchQuery}
                              onChange={(e) => this.handleSearchValue(e.target.value)}
                              // onClick={this.handleSuggestionClick}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={searchIcon} className="ml-10" />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="filter-chips-listing-wrap">
                  <ul className="custom-chip-list">
                    {this.state.checkedFilterItems.map((item: any, index: any) => (
                      <li key={index} className="chip-item">
                        {Object.keys(item).map((key) => (
                          <React.Fragment key={key}>
                            {item[key].length === 1 ? (
                              `${item[key][0]}`
                            ) : (
                              `${getCategoryName(item[key][0], this.state.filterArray)} (${item[key].length})`
                            )}
                            <i className="delete-ic">
                              <img src={closeIconBold} alt="delete" onClick={() => this.removeFilters(getCategoryValue(item[key][0], this.state.filterArray))} />
                            </i>
                          </React.Fragment>
                        ))}
                      </li>
                    ))}
                  </ul>
                </Box>
                {
                  this.state.isLoading ?
                    // <Loader loading={this.state.isLoading} contentLoader={true} />
                    <AssetDashboardSkeleton />
                    :
                    <Grid className="card-listing-wrap">
                      <div
                        // onScroll={() => this.onScroll()}
                        // ref={this.listInnerRef}
                        className="custom-scrollbar"
                        style={{ height: "100vh", overflowY: "auto", overflowX: "hidden", maxHeight: 'calc(100vh - 263px)', padding: '2px' }}
                      >
                        <Grid container className="grid-even-asset">
                          {this.state.assetsData.user_library?.data?.attributes?.length === 0 ?
                            <>
                              {this.emptyTableInfo()}
                            </>
                            :
                            this.state.assetsData.user_library?.data?.attributes?.map((item: any) => {
                              return (
                                <Grid item xs={6} md={3} key={item.id} className="card-wrap">
                                  <Card className={"asset-card"}>
                                    <div data-item-id="open-preview-modal" className="cursor-pointer" onClick={() => {
                                      trackEvent('assets_viewed', "User clicks on a particular assets to view or download it", {
                                        dewey_code:"B3.6",flow:6,
                                        asset_name:item.name,
                                        view_type:"viewed"
                                      });
                                      if (item.file_type === 'links') {
                                        this.viewFile(item.url_file)
                                      } else {
                                        this.openPreviewModal(item.id)
                                      }
                                    }}>
                                      <Box
                                        style={{ height: '114px' }}
                                        // image={item.banner_image !== null ? item.banner_image : defaultAsset}
                                        // title="Builder.ai"
                                        className={item.file_type == "documents" || item.file_type == "links" ? "has-light-bg asset-image" : "asset-image"}
                                      >
                                        {/* <img src={item.banner_image !== null ? item.banner_image : defaultAsset} /> */}
                                        <img src={item.file_type !== "images" ? item.banner_image?.url : item.library_file.url}></img>

                                      </Box>
                                      <CardContent className="card-content-wrap">
                                        <div className="asset-title-layout">
                                          <Typography className="asset-type">
                                            {capitalizeFirstLetter(item.file_type)}
                                          </Typography>
                                          <Box className="asset-title-wrapper">
                                            <Box className="asset-title">
                                              {capitalizeFirstLetter(item.name)}
                                              <Box className="info-wrapper">
                                                {item.description?.length ? <Tooltip 
                                                  placement="top"
                                                  title={
                                                    <React.Fragment>
                                                      <Box className="tooltip-desc">
                                                        <Typography className="info-heading">
                                                          {item.name}
                                                        </Typography>
                                                        <Box className="info-desc">
                                                          {item.description}
                                                        </Box>
                                                      </Box>
                                                    </React.Fragment>
                                                  }
                                                  PopperProps={{
                                                    className: 'info-tooltip'
                                                  }}
                                                >
                                                  <img className="info-icon" src={infoIcon} alt="infoIcon"></img>
                                                </Tooltip>:""}                                           
                                              </Box>
                                            </Box>
                                          </Box>
                                        </div>
                                      </CardContent>
                                    </div>
                                    <CardActions className="asset-actions">
                                      {/* {
                                        item.library_download ===  true && */}
                                      <Box>
                                        {
                                          item.file_type === 'links' ?
                                            <Button data-test-id="download-from-list" onClick={() => this.viewFile(item.url_file)} className="custom-btn light-btn btn-sm download-btn" variant="contained">
                                              <Typography className="asset-download-text">
                                                View
                                              </Typography>
                                            </Button>
                                            :

                                            <Button data-test-id="download-from-list"
                                              onClick={() => {
                                                trackEvent('assets_viewed', "User clicks on a particular assets to view or download it", {
                                                  dewey_code:"B3.6",flow:6,
                                                  asset_name:item.name,
                                                  view_type:"downloaded"
                                                });
                                                this.handleCircularLoading(item.name, true);
                                                downloadFile(item.library_file?.url, item.name, (loading: boolean) => {
                                                  this.handleCircularLoading(item.name, loading);
                                                });
                                              }}
                                              disabled={!item.library_download}
                                              className="custom-btn light-btn btn-sm download-btn"
                                              variant="contained"
                                              startIcon={circularLoadingStates[item.name] && <CircularProgress />}
                                            >
                                              <Typography className="asset-download-text">
                                                Download
                                              </Typography>
                                            </Button>
                                        }
                                      </Box>
                                      {/* } */}
                                    </CardActions>
                                  </Card>
                                </Grid>
                              )
                            })
                          }
                          {
                            this.state.secondLoading &&
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh', width: '100%' }}>
                              <CircularProgress />
                            </div>
                          }
                        </Grid>

                        {(this.state.assetsData.user_library.meta?.total_count > 12 && this.state.assetsData.user_library?.data?.attributes?.length !== 0) &&
                          <Box className="table-page-info-block">
                            <Box className="pagination-label-wrapper">
                              <Typography className="pagination-text">
                                {`Showing ${getPageCount(this.state.currentPage,this.state.assetsData.user_library.meta.total_count,12)} out of ${this.state.assetsData.user_library.meta.total_count} results`}
                              </Typography>
                            </Box>
                            <Box className="pagination-wrapper">
                              <Pagination
                                data-test-id="pagination"
                                count={this.state.assetsData.user_library.meta.total_pages}
                                onChange={(event, page) => this.setState({ currentPage: page })}
                                page={this.state.currentPage}
                                shape="rounded" />
                            </Box>
                          </Box>
                        }
                      </div>
                    </Grid>
                }
                <Modal
                  style={{ background: "#f8f9fa", zIndex: 99 }}
                  open={this.state.previewModal}
                  onClose={this.closePreviewModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  className="asset-modal"
                  BackdropProps={{
                    className: "custom-modal-backdrop"
                  }}
                >
                  <Box className="modal-body">
                    {/* <Box className="modal-header">
                      <IconButton data-test-id="btnCloseUploadModal1" disableRipple className="close-btn" onClick={() => this.closePreviewModal()}><img src={closeIcon} alt="close" /></IconButton>
                    </Box> */}
                    <Box className="modal-content">
                      <Grid container className="asset-main-row">
                        <Grid item xs={9} md={9} className="asset-preview-col">
                          <Box className="preview-main-container">
                            <Carousel
                              className="Carousel"
                              navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                                style: {
                                  boxShadow: '0px 5.33333px 21.33334px 0px rgba(0, 0, 0, 0.15)',
                                  backgroundColor: '#FFFFFF',
                                  color: '#000000',
                                  borderRadius: '66.667px',
                                  opacity: 0.6
                                }
                              }}
                              autoPlay={false}
                              navButtonsAlwaysVisible={true}
                              indicators={false}
                              onChange={(index: any) => {
                                let arrayData = this.state.assetsData.user_library?.data?.attributes.filter((item: any) => {
                                  return item.file_type !== 'links'
                                })
                                this.handleCarousel(arrayData?.[index].id)
                              }}
                            >
                              {
                                this.getAssetArray()?.filter((item: any) => {
                                  return item.file_type !== 'links'
                                })?.map((item: any, i: any) => {
                                  return (
                                    <Paper
                                      key={i}
                                      className="slide-inner-wrapper"
                                      style={{
                                        width: '100%',
                                        height: '100%',
                                        // position: 'absolute',
                                        padding: item.file_type === "documents" ? '30' : '60',
                                        backgroundColor: 'transparent',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: 'flex',
                                        // top:"50%",
                                        // transform:'translateY(-50%)'
                                      }}>
                                      {
                                        item.file_type === "documents" &&
                                        <div style={{overflowY:"scroll",height:"80vh"}}>
                                          <Document
                                            data-test-id="document"
                                            loading={<Loader loading={true} />}
                                            options={{ workerSrc: `/pdf.worker.js` }}
                                            file={item.library_file.url}
                                            onLoadSuccess={this.onDocumentLoadSuccess}
                                          >
                                            {Array.apply(null, Array(this.state.numPages))
                                              .map((x, i) => i + 1)
                                              .map(page => {
                                              return(<Page
                                                pageNumber={page}
                                                renderAnnotationLayer={false}
                                                renderTextLayer={false}
                                                width={600}
                                              />)}
                                              )}
                                          </Document>
                                        </div>
                                      }
                                      {
                                        item.file_type === "images" &&
                                        <Box className="image-wrap">
                                          <img src={item.library_file.url} />
                                        </Box>
                                      }
                                      {
                                        item.file_type === "videos" &&
                                        <div className="video-wrap">
                                          <video controls autoPlay>
                                            <source src={item.library_file.url} type="video/mp4" />
                                            Your browser does not support the video tag.
                                          </video>
                                        </div>
                                      }
                                    </Paper>
                                  )
                                })
                              }
                            </Carousel>
                          </Box>
                        </Grid>
                        <Grid item xs={3} md={3} className="asset-info-col">
                          <Box className="preview-side-container">
                            {
                              this.state.singleAssetData.user_library.data?.attributes?.map((item: any, i: any) => {
                                return (
                                  <Box className="side-content-wrap">
                                    <Box className="side-content-header">
                                      <IconButton data-test-id="btnCloseUploadModal1" disableRipple className="close-btn" onClick={() => this.closePreviewModal()}><img src={closeIcon} alt="close" /></IconButton>
                                    </Box>
                                    <Box className="side-content-title-block">
                                      <Typography className="side-container-heading">
                                        {capitalizeFirstLetter(item.name)}
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <Box className="side-content-desc">
                                        {item.description}
                                      </Box>
                                      <Box className="file-info-list">
                                        <Typography className="file-info-heading" >File info</Typography>
                                        <Box className="file-info-item">
                                          <Box className="file-info-text"><Typography>File type</Typography></Box>
                                          <Box className="file-value-text"><Typography>{item.document_type}</Typography></Box>
                                        </Box>
                                        <Box className="file-info-item">
                                          <Box className="file-info-text"><Typography>Date added</Typography></Box>
                                          <Box className="file-value-text"><Typography>{getMonthDateFormat(item.date_added)}</Typography></Box>
                                        </Box>
                                        <Box className="file-info-item">
                                          <Box className="file-info-text"><Typography>Size</Typography></Box>
                                          <Box className="file-value-text"><Typography>{item.document_size}</Typography></Box>
                                        </Box>
                                        {item.file_type === "documents" &&
                                          <Box className="file-info-item">
                                            <Box className="file-info-text"><Typography>Pages</Typography></Box>
                                            <Box className="file-value-text"><Typography>{item.pdf_pages}</Typography></Box>
                                          </Box>
                                        }
                                        {item.file_type === "videos" &&
                                          <>
                                            <Box className="file-info-item">
                                              <Box className="file-info-text"><Typography>Duration</Typography></Box>
                                              <Box className="file-value-text"><Typography>{item.duration}</Typography></Box>
                                            </Box>
                                            <Box className="file-info-item">
                                              <Box className="file-info-text"><Typography>Resolution</Typography></Box>
                                              <Box className="file-value-text"><Typography>{item.resolution}</Typography></Box>
                                            </Box>
                                          </>
                                        }
                                        {item.file_type === "images" &&
                                          <>
                                            <Box className="file-info-item">
                                              <Box className="file-info-text"><Typography>Resolution</Typography></Box>
                                              <Box className="file-value-text"><Typography>{item.resolution}</Typography></Box>
                                            </Box>
                                          </>
                                        }
                                        <Box className="file-info-item">
                                          <Box className="file-info-text"><Typography className="file-info-text">Language</Typography></Box>
                                          <Box className="file-value-text"><Typography className="file-value-text">English</Typography></Box>
                                        </Box>
                                        {
                                          item.library_download === true &&
                                          <Box className="share-wrapper">
                                            <Typography className="share-text mt-16 mb-5" >Share</Typography>
                                            <Tooltip open={this.state.copiedText} title="Link copied to clipboard">
                                              <TextField
                                                id="share-input-height"
                                                value={item.library_file_url}
                                                size="small"
                                                fullWidth
                                                variant="outlined"
                                                className="share-input"
                                                InputProps={{
                                                  style: { color: '#83889E', height: 60 },
                                                  readOnly: true,
                                                  endAdornment: <InputAdornment data-test-id="copy-test-id" onClick={() => {
                                                    this.openCopyText()
                                                    copyToClipboard(item.library_file_url)
                                                  }} className="share-pointer" position="end"><img src={copyIcon} /></InputAdornment>,
                                                }}
                                              />
                                            </Tooltip>
                                          </Box>
                                        }
                                      </Box>
                                      {
                                        item.library_download === true &&
                                        <Box className="a-download-button">
                                          <Button
                                            data-test-id="download-from-list"
                                            type="button"
                                            onClick={() => {
                                              trackEvent('assets_viewed', "User clicks on a particular assets to view or download it", {
                                                dewey_code:"B3.6",flow:6,
                                                asset_name:item.name,
                                                view_type:"downloaded"
                                              });
                                              this.handleCircularLoading(item.name, true);
                                              downloadFile(item.library_file_url, item.name, (loading: boolean) => {
                                                this.handleCircularLoading(item.name, loading);
                                              });
                                            }}
                                            className="custom-btn green-btn btn-sm"
                                            startIcon={circularLoadingStates[item.name] && <CircularProgress />}
                                          >
                                            <Typography className="asset-download-text">
                                              Download
                                            </Typography>
                                          </Button>
                                        </Box>
                                      }
                                    </Box>
                                  </Box>
                                )
                              })
                            }
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Modal>
              </Box>
            </Grid>
          </Grid>
        </Grid >
      </Box >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  container: {
    background: '#F8F9FA',
    height: '100vh',
    overflow: 'auto',
  },
  mainContainer: {
    padding: '16px 0 0'
  },
  centerAlign: {
    alignItems: 'center'
  },
  rightAlign: {
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
};
const previewModalstyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '1344px',
  bgcolor: 'background.paper',
  boxShadow: 3
};
// Customizable Area End
