export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upArrow = require("../assets/up_arrow.svg");
export const conversation = require("../assets/conversion.svg");
export const deals = require("../assets/dealsmain.svg");
export const leads = require("../assets/leads.svg");
export const leadsBar = require("../assets/leads_bar.svg");
export const comissionEarned = require("../assets/comission_earned.svg");
export const minimumCommitement = require("../assets/minimum_commitement.svg");
export const valuePipeline = require("../assets/value_pipeline.svg");
export const visiblityBlueIc = require("../assets/visiblity_purple.svg");
export const pdfView = require("../assets/pdf.svg");
export const graphBars = require("../assets/bars.svg");

