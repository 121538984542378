import { AnalyticsBrowser } from '@segment/analytics-next';
import moment from 'moment-timezone';
import { getEnviromentValue } from './commonUsage';

const _this: any = window;
const segment_key = getEnviromentValue('WRITE_KEY') || ""
const analytics = AnalyticsBrowser.load({ writeKey: segment_key })

// Function to get IP address, country, and region using ipapi.co
let cachedIpInfo: any = null;
const getUserIpInfo = async (): Promise<{ user_ip: string, user_country: string, user_region: string, user_city: string }> => {
  if (cachedIpInfo) {
    return cachedIpInfo;
  }

  try {
    const response = await fetch('https://ipapi.co/json/');
    if (!response.ok) {
      throw new Error('Failed to fetch IP info');
    }
    const data = await response.json();
    cachedIpInfo = {
      user_ip: data.ip,
      user_country: data.country_name,
      user_region: data.region,
      user_city: data.city
    };
    return cachedIpInfo;
  } catch (error) {
    console.error('Error fetching IP info:', error);
    return { user_ip: '', user_country: '', user_region: '', user_city: '' };
  }
};

// Common properties function
const getCommonProperties = async () => {
  const userIpInfo = await getUserIpInfo();
  const page_url = window.location.href.includes('?') ? window.location.href.split('?')[0] : window.location.href;
  const browser = /Firefox/i.test(navigator.userAgent) ? "Firefox" : /Edge/i.test(navigator.userAgent) ? "Microsoft Edge" : /Chrome/i.test(navigator.userAgent) ? "Google Chrome" : /Safari/i.test(navigator.userAgent) ? "Safari" : /Opera|OPR/i.test(navigator.userAgent) ? "Opera" : /Trident/i.test(navigator.userAgent) || /MSIE/i.test(navigator.userAgent) ? "Internet Explorer" : "Unknown";

  return {
    user_id: localStorage.getItem('user_id'),
    // event_time: moment().toString(),
    source_system: "PRM",
    // source_environment: import.meta.env.VITE_CURRENT_ENVIRONMENT || _this.env.current_environment,
    // timezone:  moment.tz.guess(),
    page_url: page_url,
    browser: browser,
    internal_user_email: localStorage.getItem('email'),
    builder_user_ip: userIpInfo.user_ip,
    builder_user_country: userIpInfo.user_country,
    builder_user_region: userIpInfo.user_region,
    builder_user_city: userIpInfo.user_city,
    builder_user_os: navigator.platform,
    builder_session_id: startSession().sessionId,
    builder_session_start_time: startSession().time,
    builder_session_latest_time: moment().toString(),
    stage:"B",
    block:"3"
  };
};

// trackEvent with error handling for analytics.track
const trackEvent = async (eventName: string, eventDescription: any, customProperties = {}) => {
  //   const environment = import.meta.env.VITE_CURRENT_ENVIRONMENT || _this.env.current_environment;
  //   if (environment === "local") return;

  try {
    const commonProperties = await getCommonProperties();
    const properties = {
      ...commonProperties,
      event_description: eventDescription,
      event_name: eventName,
      ...customProperties
    };

    analytics.track(eventName, properties);
  } catch (error) {
    console.error('Error sending event to Segment:', error);
  }
};

function createUniqueId() {
  return Date.now().toString(36) + Math.random().toString(36).substr(2, 9);
}

function startSession() {
  let sessionData = localStorage.getItem('session') as any;
  if (!sessionData) {
    const sessionId = createUniqueId();
    localStorage.setItem('session', JSON.stringify({ sessionId, time: new Date().toString() }));
  } else {
    sessionData = JSON.parse(sessionData)
    if (moment(sessionData.time).format("mm") > "30") {
      const sessionId = createUniqueId();
      localStorage.setItem('session', JSON.stringify({ sessionId, time: new Date().toString() }));
    }
  }
  return JSON.parse(localStorage.getItem('session') as any)
}

export { trackEvent };