import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import './sidenav.css';
import { IconButton, Modal, Box, Typography, TextField, Button, Grid } from "@material-ui/core";
import { useRunEngine } from "../../blocks/utilities/src/hooks/useRunEngine";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { Message } from "../../framework/src/Message";
import { useBlockHelpers } from "../../blocks/utilities/src/hooks/useBlockHelpers";
import { Formik, FormikProps } from "formik";
import Select, { components } from "react-select";
import { getStorageData } from "../../framework/src/Utilities";
import * as Yup from 'yup';


const closeIcon = require("./close.svg")
const planIllustration = require('./plan_illustration.png');
const checkIcon = require('./check_mark.svg');


const subscribedMessages = [
    MessageEnum.RestAPIResponceMessage,
    MessageEnum.SessionResponseMessage,
];

export const HelpAndSupport = ({openModal,closeModal}:{openModal:boolean,closeModal:()=>void}) => {
    const supportTicketId = useRef("")
    const [openConfirmationDialog, setConfirmationOpen] = useState(false);
    const [onDropDownBlur,setOnDropDownBlur] = useState(false)
    const [formSubmit,setFormSubmit] = useState(false)
    // const [openDialog, closeModal] = useState(false);
    const [helpOption, setHelpOption] = useState<any>("");

    const helpOptions = [
        { value: "Platform support", label: "Platform support" },
        { value: "Business support", label: "Business support" }
    ];

    const supportSchema = Yup.object().shape({
        title: Yup.string().required("required"),
        email: Yup.string().email("Please provide a valid email address.").required("Please provide a valid email address."),
        description: Yup.string().required("required"),
        help_required: Yup.string().required("required"),
    });

    const {
        sendBlockMessage,
        sendNetworkRequest,
        setReceiveCallback,
        subscribe,
        debugLog,
        unsubscribeFromMessage,
    } = useRunEngine();
    const { extractNetworkResponse, hideKeyboard, isPlatformWeb } = useBlockHelpers();

    const handleDialogClose = () => {
        closeModal();
        setHelpOption("")
        setOnDropDownBlur(false)
    };

    useEffect(() => {
        setReceiveCallback(receive);
        subscribedMessages.forEach((message) => subscribe(message));
        return () => {
            subscribedMessages.forEach((message) => unsubscribeFromMessage(message));
        };
    }, []);

    const receive = (from: string, message: Message) => {
    console.log('message: ', message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const { apiRequestCallId, responseJson } = extractNetworkResponse(message);
            console.log('apiRequestCallId: ', apiRequestCallId);
                
                console.log('responseJson: ', responseJson);
                if(supportTicketId.current ===apiRequestCallId){
                    if(responseJson.success){
                        setConfirmationOpen(true)
                    }
                    handleDialogClose()
                    setFormSubmit(false)
                }
        }
    }

    const handleConfirmationModalClose = () => {
        setConfirmationOpen(false);
        setFormSubmit(false)
    };

    const postSupportTicket = async (value:{
        title: string;
        email: string;
        description: string;
        help_required: string;
    }) => {
        setFormSubmit(true)
        const headers = {
            "Content-Type": "application/json",
            token: await getStorageData('authToken'),
        };

        const body = {
            "data": {
                "name": value.title,
                "email": value.email,
                "description": value.description,
                "help_required": value.help_required
            }
        }

        sendNetworkRequest(
            supportTicketId,
            "POST",
            "/bx_block_ticketsystem/tickets",
            headers,
            body
        );
    };

    const handleSelectChange = (selectedOption: any,formik:FormikProps<any>) => {
        setHelpOption(selectedOption);
        formik.setFieldValue("help_required",selectedOption.value)
    };

    const { Option } = components;
    const selectOption = (props: any) => (
        <Option {...props}>
            {props.data.label}
            {helpOption && helpOption.value === props.data.value && (
                <img src={checkIcon} style={{ marginLeft: '10px' }} />
            )}
        </Option>
    );
    
    return (
        <>
            <Modal
                open={openModal}
                onClose={handleDialogClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="custom-modal need-help-modal confirmation-modal modal-sm"
                BackdropProps={{
                    className: "custom-modal-backdrop"
                }}
            >
                <Formik
                    initialValues={{
                        "title": "",
                        "email": "",
                        "description": "",
                        "help_required": ""
                    }}
                    // enableReinitialize
                    validationSchema={supportSchema}
                    onSubmit={(values) => {
                        postSupportTicket(values)
                    }}
                >
                    {(formik) => (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="modal-body">
                                <div className="modal-inner-wrap">
                                    {/* {isIframeLoading ? <div className="loader-wrap"><Loader loading={true} /></div> : null} */}
                                    <div>
                                        <IconButton disableRipple className="close-btn" onClick={handleDialogClose}>
                                            <img src={closeIcon} alt="close" />
                                        </IconButton>
                                        <Typography variant="h4">
                                            Raise a support request
                                        </Typography>
                                    </div>
                                    <Box className="form-wrap">
                                        <Grid className="grid-even-space mt-1">
                                            <Grid item xs={12} md={12}>
                                                <Box className="form-element-wrap">
                                                    <label className="form-element-label">What can we help you with? <span style={{ color: "red" }}>*</span></label>
                                                    <TextField
                                                        name="title"
                                                        size="small"
                                                        variant="filled"
                                                        fullWidth
                                                        type="text"
                                                        label=""
                                                        className="secondary-form-control"
                                                        placeholder="Title"
                                                        value={formik.values.title}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched.title && Boolean(formik.errors.title)}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <Box className="form-element-wrap">
                                                    <Box className={`custom-search-dropdown-wrapper ${(formik.touched.help_required || onDropDownBlur) &&
                                                        Boolean(formik.errors.help_required) ? "custom-search-select-error" : ""}`}>
                                                        <label className="form-element-label">Help required on <span style={{ color: "red" }}>*</span></label>
                                                        <Select
                                                            name="help_required"
                                                            value={helpOption}
                                                            onChange={(option) => { handleSelectChange(option, formik) }}
                                                            options={helpOptions}
                                                            styles={selectStyle}
                                                            className="custom-search-select xsmall-listbox no-label"
                                                            components={{
                                                                IndicatorSeparator: () => null,
                                                                Option: selectOption,
                                                            }}
                                                            placeholder="Select"
                                                            onFocus={() => {
                                                                setOnDropDownBlur(false)
                                                            }}
                                                            onBlur={e => {
                                                                formik.handleBlur(e)
                                                                setOnDropDownBlur(true)
                                                            }}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Box className="form-element-wrap mt-1">
                                            <label className="form-element-label">Your email <span style={{ color: "red" }}>*</span></label>
                                            <TextField
                                                name="email"
                                                size="small"
                                                variant="filled"
                                                type="text"
                                                label=""
                                                fullWidth={true}
                                                className="secondary-form-control"
                                                placeholder="Enter email"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.email && Boolean(formik.errors.email)}
                                                helperText={formik.touched.email && Boolean(formik.errors.email) ? formik.errors.email : ""}
                                            />
                                        </Box>
                                        <Box className="form-element-wrap mt-1">
                                            <label className="form-element-label">Describe your issue briefly <span style={{ color: "red" }}>*</span></label>
                                            <TextField
                                                name="description"
                                                fullWidth
                                                variant="filled"
                                                multiline
                                                className="secondary-form-control"
                                                inputProps={{ maxLength: 2000 }}
                                                placeholder="Enter issue description"
                                                value={formik.values.description}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.description && Boolean(formik.errors.description)}
                                            />
                                        </Box>
                                    </Box>
                                    <Box className="mt-1 btn-wrap">
                                        <Button className="custom-btn gray-btn" onClick={handleDialogClose}>Cancel</Button>
                                        <Button className="custom-btn green-btn" type="submit" disabled={formSubmit}>Submit</Button>
                                    </Box>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </Modal>

            {/* L1 form success modal  */}
            <Modal
                open={openConfirmationDialog}
                onClose={handleConfirmationModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="confirmation-modal success-modal custom-modal modal-sm"
                BackdropProps={{
                    className: "custom-modal-backdrop"
                }}
            >
                <Box className="modal-body">
                    <IconButton data-test-id="btnCloseConfirmationModal" disableRipple className="close-btn" onClick={handleConfirmationModalClose}><img src={closeIcon} alt="close" /></IconButton>
                    <Box className="modal-content">
                        <Box className="image-block">
                            <img src={planIllustration} alt="success" />
                        </Box>
                        <Box className="content-wrap">
                            <Typography variant="h4">Your request is with us now</Typography>
                            <Typography>We will registered your inputs. We will reach out to you soon.</Typography>
                        </Box>
                        <Box className="btn-wrap">
                            <Button data-test-id="close-confirmation-model"
                                onClick={handleConfirmationModalClose}
                                className="custom-btn gray-btn"
                                variant="outlined">Close</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>

    )
}

const selectStyle = {
    control: (styles: any) => ({
        ...styles,
        height: 36,
        border: '1px solid #C0C3CE',
        boxShadow: 'none',
        borderRadius: 4,
        fontSize: 14,
        '&:hover': {
            // border: '1px solid #6200EA',
            boxShadow: 'none',
        },
        '&::placeholder': {
            marginTop: '10px',
            color: '#83889E',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '20px',
            fontFamily: "'Rubik', sans-serif",
        },
    }),
    menu: (provided: any) => ({
        ...provided,
        zIndex: 9999,
        borderRadius: '4px',
        border: '1px solid var(--true - grey - 3, #F5F5F5)',
        background: 'var(--0, #FFF)',
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
        paddingBottom: 0,
        paddingTop: 0,
        marginTop: 3,
    }),
    menuList: (provided: any) => ({
        ...provided,
        paddingBottom: 0,
        paddingTop: 0,
        borderRadius: 4,
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        background: state.isSelected ? '#F3EBFF' : 'white',
        color: state.isSelected ? '#3C3E49' : '#3C3E50',
        '&:hover': {
            background: '#F3EBFF',
        },
        fontSize: '16px',
        lineHeight: '24px',
        display: 'flex',
        padding: '12px 16px',
        alignItems: 'center',
        gap: '8px',
        alignSelf: 'stretch'
    }),
    noOptionsMessage: (provided: any) => ({
        ...provided,
        background: '#F3EBFF',
        display: 'flex',
        alignItems: 'center',

    }),
    singleValue: (provided: any) => ({
        ...provided,
        marginRight: '0px',
        marginTop: '12px', // Adjust margin as needed q
    }),
    dropdownIndicator: (provided: any) => ({
        ...provided,
        color: "#3C3E49",
        padding: '8px'
    }),
    valueContainer: (provided: any) => ({
        ...provided,
        padding: '2px 14px',
    }),
    input: (provided: any) => ({
        ...provided,
        marginTop: '15px',
        paddingTop: '0px',
        caretColor: 'transparent'
    })
};
